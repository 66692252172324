import { View, Text, FlatList, StyleSheet, ScrollView } from "react-native";
import React, { useCallback, useState } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { ApiGetEventList } from "../../../APIs/EventOnlineCertificate";
import { useSelector } from "react-redux";
import CardList from "../../../components/CardList";
import { dimensionHeight, resizeHeight } from "../../../helpers/responsiveSize";

export default function EventsList() {
  const [events, setEvents] = useState([]);
  const [fetchData, setFetchData] = useState(true);
  const searchQuery = useSelector((state) => state.searchQuery);
  useFocusEffect(
    useCallback(() => {
      if (searchQuery.length < 4 && fetchData) {
        console.log("search < 4");
        ApiGetEventList(setEvents);
        setFetchData(false);
      } else if (searchQuery.length >= 4) {
        console.log("start search");
        ApiGetEventList(setEvents, searchQuery);
        setFetchData(true);
      }
    }, [searchQuery])
  );

  const renderItem = ({ item }) => {
    return <CardList EventDetail={item} />;
  };
  return (
    <View style={styles.body}>
      <Text>{searchQuery}</Text>
      <View style={{ height: dimensionHeight * 0.65 }}>
        <FlatList data={events} renderItem={renderItem} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  body: { flex: 1, width: "100%" },
});
