import { Image, StyleSheet, View } from "react-native";
import { Text, Portal, useTheme, Searchbar } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { dimensionWidth } from "../../../helpers/responsiveSize";
import { setSearchQuery } from "../../../store/actions/actionCreator";
import EventsList from "./EventsList";

export default function MainContainer() {
  const { colors: theme } = useTheme();
  const dispatch = useDispatch();
  const searchQuery = useSelector((state) => state.searchQuery);

  const onChangeSearch = (query) => dispatch(setSearchQuery(query));
  return (
    <View
      style={{ ...styles.mainContainer, backgroundColor: theme.background }}
    >
      <Image
        source={require("../../../assets/logo.png")}
        resizeMode="contain"
        style={{ width: 100, height: 100, marginBottom: 15 }}
        alt="Portal Kegiatan"
      />
      <Searchbar
        placeholder="Search event"
        style={{ width: "100%", marginBottom: 15 }}
        onChangeText={onChangeSearch}
        value={searchQuery}
        mode="bar"
      />
      <EventsList />
    </View>
  );
}
const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    width: dimensionWidth >= 500 ? 500 : "100%",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.58,
    shadowRadius: 16.0,

    elevation: 24,
  },
});
