import { View, Text, StyleSheet } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useTheme } from "react-native-paper";

export default function VerificationData({ iconName, name, description }) {
  const theme = useTheme().colors;
  return (
    <View style={styles.container}>
      <View style={styles.tagName}>
        <MaterialCommunityIcons
          name={iconName}
          size={20}
          color={theme.onBackground}
          style={{ marginEnd: 5 }}
        />
        <Text style={styles.category}>{name}</Text>
      </View>
      <Text style={{ textTransform: name != "Status" ? "none" : "capitalize" }}>
        {description}
      </Text>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    alignItems: "flex-start",
    marginBottom: 10,
  },
  tagName: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  category: { fontWeight: "bold" },
});
