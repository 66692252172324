const config = {
  screens: {
    LandingPage: {
      path: "/",
      screens: {},
    },
    EventDetail: "event/:EventId",
    CertificateVerification: "certificate/:CertificateId",
  },
};

export const linking = {
  prefixes: ["https://192.168.2.227:19006/"],
  config,
};
