import { View, Text, ImageBackground, StyleSheet } from "react-native";
import React, { useCallback, useState } from "react";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import { RANDOM_IMAGE } from "../../../constant/baseUrl";

export default function CoverImage() {
  const [image, setImage] = useState();
  const isFocused = useIsFocused();

  return (
    <View style={styles.body}>
      <Text>
        {/* {image && console.log(`data:image/webp;base64,${encode(image, true)}`)} */}
      </Text>
      {/* <Text>asdasdasd</Text> */}

      <ImageBackground
        source={{
          uri: RANDOM_IMAGE + "/category/c32376fa-f33f-4904-8760-e017f04c99e4",
        }}
        resizeMode="cover"
        imageStyle={{ borderRadius: 20 }}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 20,
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 12,
          },
          shadowOpacity: 0.58,
          shadowRadius: 16.0,

          elevation: 24,
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    flex: 2,
    justifyContent: "flex-end",
    alignItems: "center",
    marginEnd: 20,
  },
});
