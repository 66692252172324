import { View, StyleSheet } from "react-native";
import React, { useState } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Button, Text } from "react-native-paper";
import VerificationData from "../../../components/VerificationData";
import SignatureInfo from "./SignatureInfo";
import CertificationReceiver from "./CertificationReceiver";

export default function CertificateDetail({ data }) {
  return (
    <>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: 20,
        }}
      >
        <MaterialCommunityIcons
          name="check-decagram"
          size={20}
          color={"green"}
        />
        <Text>
          Status dokumen:
          <Text style={{ color: "green" }}> AKTIF</Text>
        </Text>
      </View>
      <VerificationData
        iconName={"text-box-outline"}
        name={"Jenis Dokumen"}
        description={"Sertifikat"}
      />
      <VerificationData
        iconName={"card-bulleted-outline"}
        name={"Nomor Dokumen"}
        description={data.id.toUpperCase()}
      />
      <CertificationReceiver data={data} />
      <SignatureInfo data={data} />
      <Text
        variant="bodyMedium"
        style={{ fontWeight: "600", paddingHorizontal: 10 }}
      >
        Dokumen ini telah ditandatangani secara elektronik menggunakan
        sertifikat elektronik {data.Signature.Company.name}
      </Text>
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    alignItems: "flex-start",
    marginBottom: 10,
  },
  tagName: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  category: { fontWeight: "bold" },
});
