import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top-right",
  iconColor: "white",
  customClass: {
    popup: "colored-toast",
  },

  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
});

export const alertTopRight = async ({ background, icon, title }) => {
  Toast.fire({
    background,
    icon,
    title,
  });
};
