import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View } from "react-native";
import {
  MD3LightTheme as DefaultTheme,
  Provider as PaperProvider,
} from "react-native-paper";
import { Provider } from "react-redux";
import store from "./store";
import { NavigationContainer } from "@react-navigation/native";
import NavigationPage from "./src/NavigationPage";
import { linking } from "./path";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { renderToString } from "react-dom/server";
const helmetContext = {};
export default function App() {
  return (
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <PaperProvider theme={theme}>
          <NavigationContainer
            linking={linking}
            fallback={<Text>Loading...</Text>}
          >
            <NavigationPage />
            <StatusBar style="auto" />
          </NavigationContainer>
        </PaperProvider>
      </Provider>
    </HelmetProvider>
  );
}
const html = renderToString(App);
const { helmet } = helmetContext;
const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: "rgb(177, 44, 22)",
    onPrimary: "rgb(255, 255, 255)",
    primaryContainer: "rgb(255, 218, 211)",
    onPrimaryContainer: "rgb(63, 4, 0)",
    secondary: "rgb(119, 87, 80)",
    onSecondary: "rgb(255, 255, 255)",
    secondaryContainer: "rgb(255, 218, 211)",
    onSecondaryContainer: "rgb(44, 21, 17)",
    tertiary: "rgb(110, 92, 46)",
    onTertiary: "rgb(255, 255, 255)",
    tertiaryContainer: "rgb(249, 224, 166)",
    onTertiaryContainer: "rgb(36, 26, 0)",
    error: "rgb(186, 26, 26)",
    onError: "rgb(255, 255, 255)",
    errorContainer: "rgb(255, 218, 214)",
    onErrorContainer: "rgb(65, 0, 2)",
    background: "rgb(255, 251, 255)",
    onBackground: "rgb(32, 26, 25)",
    surface: "rgb(255, 251, 255)",
    onSurface: "rgb(32, 26, 25)",
    surfaceVariant: "rgb(245, 221, 217)",
    onSurfaceVariant: "rgb(83, 67, 64)",
    outline: "rgb(133, 115, 111)",
    outlineVariant: "rgb(216, 194, 189)",
    shadow: "rgb(0, 0, 0)",
    scrim: "rgb(0, 0, 0)",
    inverseSurface: "rgb(54, 47, 45)",
    inverseOnSurface: "rgb(251, 238, 235)",
    inversePrimary: "rgb(255, 180, 165)",
    elevation: {
      level0: "transparent",
      level1: "rgb(251, 241, 243)",
      level2: "rgb(249, 234, 236)",
      level3: "rgb(246, 228, 229)",
      level4: "rgb(246, 226, 227)",
      level5: "rgb(244, 222, 222)",
    },
    surfaceDisabled: "rgba(32, 26, 25, 0.12)",
    onSurfaceDisabled: "rgba(32, 26, 25, 0.38)",
    backdrop: "rgba(59, 45, 42, 0.4)",
  },
};
