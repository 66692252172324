import { View, Image, TouchableOpacity } from "react-native";
import React, { useEffect } from "react";
import { Text } from "react-native-paper";

export default function Header({ navigation }) {
  return (
    <TouchableOpacity
      style={{ flexDirection: "row", alignItems: "center", marginBottom: 15 }}
      onPress={() => navigation.navigate("LandingPage")}
    >
      <Image
        source={require("../assets/logo.png")}
        style={{ width: 75, height: 75, marginEnd: 10 }}
      />
      <View>
        <Text
          variant="titleMedium"
          style={{ fontWeight: "600", flexWrap: "wrap" }}
        >
          SEKOLAH TINGGI AGAMA ISLAM
        </Text>
        <Text
          variant="titleLarge"
          style={{ fontWeight: "600", flexWrap: "wrap" }}
        >
          SAYID SABIQ INDRAMAYU
        </Text>
      </View>
    </TouchableOpacity>
  );
}
