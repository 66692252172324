import { createStackNavigator } from "@react-navigation/stack";
import LandingPage from "./LandingPage/LandingPage";
import EventDetail from "./EventDetail/EventDetail";
import CertificateVerification from "./CertificateVerification/CertificateVerification";

const Stack = createStackNavigator();

export default function NavigationPage() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="LandingPage"
        component={LandingPage}
        options={{ title: "Event Portal" }}
      />
      <Stack.Screen
        name="EventDetail"
        component={EventDetail}
        options={{ title: "" }}
      />
      <Stack.Screen
        name="CertificateVerification"
        component={CertificateVerification}
        options={{ title: "" }}
      />
    </Stack.Navigator>
  );
}
