import { Dimensions, PixelRatio } from "react-native";
export const dimensionWidth = Dimensions.get("window").width;
export const dimensionHeight = Dimensions.get("window").height;

export function resizeWidth(y) {
  // const dimensionWidth = Dimensions.get("window").width;
  // const ResolutionWidth = PixelRatio.getPixelSizeForLayoutSize(dimensionWidth);
  return (dimensionWidth / 480) * y;
}
export function resizeHeight(x) {
  // const ResolutionHeight =
  //   PixelRatio.getPixelSizeForLayoutSize(dimensionHeight);

  return (dimensionHeight / 581) * x;
}

export function resizeFont(fontSize) {
  const fontScale = PixelRatio.getFontScale();
  const dimensionWidth = Dimensions.get("window").width / 480;
  return Math.floor(fontSize * fontScale * dimensionWidth);
  // return fontSize;
}

// export function resizeWidthMenuContainer(padding) {
//   const totalPadding = resizeWidth(padding) * 2;
//   const availArea = dimensionWidth - totalPadding;
// }
