import { View, StyleSheet } from "react-native";
import React, { useCallback, useState } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { ApiGetEventDetail } from "../../APIs/EventOnlineCertificate";
import CardList from "../../components/CardList";
import { dimensionWidth, resizeWidth } from "../../helpers/responsiveSize";
import { ActivityIndicator } from "react-native-paper";
import { addHours } from "../../helpers/datePrototype";
import SearchCertificate from "../../components/SearchCertificate";

import { DOMAIN_URL, EVENT_MANAGEMENT } from "../../constant/baseUrl";
import Header from "../../components/Header";
import MetaTags from "../../components/MetaTags";

export default function EventDetail({ route, navigation }) {
  const [EventDetail, setEventDetail] = useState({});
  const { EventId } = route.params;
  const focusEffect = async () => {
    try {
      const data = await ApiGetEventDetail(EventId);

      setEventDetail((state) => data);
      navigation.setOptions({ title: data.name });
    } catch (error) {
      console.log(error);
    }
  };
  useFocusEffect(
    useCallback(() => {
      focusEffect();
    }, [EventId])
  );
  return (
    <View style={styles.body}>
      <MetaTags
        description={EventDetail.description}
        title={EventDetail.name}
        urlArticle={`${DOMAIN_URL}/event/${EventDetail.id}`}
        urlImage={`${EVENT_MANAGEMENT}/event/image/${EventDetail.id}`}
      />

      <View style={{ alignItems: "flex-start", width: "100%" }}>
        <Header navigation={navigation} />
      </View>
      {/* <View style={{ width: "100%" }}>
        <AdSense.Google
          client="ca-pub-2110976780015409"
          slot="8283354478"
          style={{ display: "block" }}
          format="auto"
          responsive="true"
          layoutKey="-gw-1+2a-9x+5c"
        />
      </View> */}
      {addHours(new Date(EventDetail.time), EventDetail.duration).getTime() <
        Date.now() && <SearchCertificate EventId={EventId} />}
      {EventDetail.id ? (
        <CardList EventDetail={EventDetail} isEventDetail={true} />
      ) : (
        <ActivityIndicator />
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  body: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: dimensionWidth > 500 ? resizeWidth(20) : 5,
  },
});
