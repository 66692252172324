import { NAVIGATION, SEARCH_QUERY } from "./actionType";

export function searchQuery(data) {
  return {
    type: SEARCH_QUERY,
    data,
  };
}

export function navigationObject(data) {
  return {
    type: NAVIGATION,
    data,
  };
}

export function setSearchQuery(data) {
  return async (dispatch, getState) => {
    try {
      await dispatch(searchQuery(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function setNavigationObject(data) {
  return async (dispatch, getState) => {
    try {
      // console.log(data);
      await dispatch(navigationObject(data));
    } catch (error) {
      console.log(error);
    }
  };
}
