import { View, Text, StyleSheet, ImageBackground } from "react-native";
import React, { useCallback, useState } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { ApiVerifyCertificate } from "../../APIs/EventOnlineCertificate";
import CertificateDetail from "./components/CertificateDetail";
import { ActivityIndicator } from "react-native-paper";
import { resizeHeight } from "../../helpers/responsiveSize";
import Header from "../../components/Header";
import { EVENT_MANAGEMENT } from "../../constant/baseUrl";

export default function CertificateVerification({ route, navigation }) {
  const { CertificateId } = route.params;
  const [certificateData, setCertificateDetail] = useState({});
  const [bodyCardSize, setBodyCardSize] = useState(0);

  const getData = async () => {
    try {
      const data = await ApiVerifyCertificate(CertificateId);
      setCertificateDetail((state) => data);
    } catch (error) {
      // navigation.navigate("LandingPage");
    }
  };
  useFocusEffect(
    useCallback(() => {
      getData();
    }, [])
  );
  return (
    <View style={styles.body}>
      {!certificateData.id ? (
        <ActivityIndicator />
      ) : (
        <>
          <Header navigation={navigation} />

          <ImageBackground
            source={{
              uri: `${EVENT_MANAGEMENT}/logo/${certificateData.Signature.Company.id}`,
            }}
            resizeMode="contain"
            imageStyle={{
              ...styles.imageBackgroundStyle,
            }}
            style={{
              padding: 10,
              borderRadius: 20,
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 8,
              },
              shadowOpacity: 0.44,
              shadowRadius: 10.32,

              elevation: 16,
            }}
          >
            {!certificateData.id ? (
              <ActivityIndicator size={"large"} />
            ) : (
              <CertificateDetail data={certificateData} />
            )}
          </ImageBackground>
        </>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  body: {
    flex: 1,
    padding: 20,
    borderRadius: 20,
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,

    // marginTop: resizeHeight(50),

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  imageBackgroundStyle: {
    opacity: 0.25,
  },
});
