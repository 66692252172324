import { NAVIGATION } from "../actions/actionType";

const initialNavigation = {};

export default function navigationReducer(state = initialNavigation, action) {
  switch (action.type) {
    case NAVIGATION:
      return action.data;
    default:
      return state;
  }
}
