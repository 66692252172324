import { useCallback, useEffect, useState } from "react";
import { Image, StyleSheet, View } from "react-native";
import { Text, Portal, useTheme, Searchbar } from "react-native-paper";
import { dimensionWidth } from "../../helpers/responsiveSize";
import CoverImage from "./components/CoverImage";
import MainContainer from "./components/MainContainer";
import Favicon from "react-favicon";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";
import { setNavigationObject } from "../../store/actions/actionCreator";
import { trimString } from "../../helpers/characterSet";
import { DOMAIN_URL } from "../../constant/baseUrl";
import MetaTags from "../../components/MetaTags";

export default function LandingPage({ navigation }) {
  const { colors: theme } = useTheme();

  const dispatch = useDispatch();
  useFocusEffect(
    useCallback(() => {
      console.log(navigation);
      dispatch(setNavigationObject(navigation));
    }, [])
  );
  return (
    <View
      style={{
        width: "100%",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-around",
        padding: dimensionWidth > 500 ? 50 : 10,
      }}
    >
      {/* <Helmet> */}
      <MetaTags
        title={"Portal kegiatan Sekolah Tinggi Sayid Sabiq Indramayu"}
        description={"Portal kegiatan Sekolah Tinggi Sayid Sabiq Indramayu"}
        urlArticle={DOMAIN_URL}
        urlImage={""}
      />
      {/* <meta charSet="utf-8" />
        <meta
          property="og:title"
          content={`${trimString(
            "Portal kegiatan Sekolah Tinggi Sayid Sabiq Indramayu",
            35
          )}`}
        />
        <meta
          name="description"
          content={`${trimString(
            "Portal kegiatan Sekolah Tinggi Sayid Sabiq Indramayu",
            155
          )}`}
        />
        <meta
          property="og:description"
          content={`${trimString(
            "Portal kegiatan Sekolah Tinggi Sayid Sabiq Indramayu",
            65
          )}`}
        />
        <meta property="og:url" content={`${DOMAIN_URL}`} />

        <meta property="og:type" content={`website`} /> */}
      {/* </Helmet> */}
      {dimensionWidth > 500 && <CoverImage />} <MainContainer />
    </View>
  );
}
