import { Helmet } from "react-helmet-async";
import { trimString } from "../helpers/characterSet";

export default function MetaTags({ title, description, urlArticle, urlImage }) {
  return (
    <Helmet prioritizeSeoTags>
      <meta charset="UTF-8" />

      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={urlArticle} />
      <meta property="og:locale" content="id_ID" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={trimString(title, 35)} />
      <meta property="og:description" content={trimString(description, 155)} />
      <meta property="og:url" content={urlArticle} />
      {/* <meta
        property="article:published_time"
        content="2023-04-07T10:15:35+00:00"
      />
      <meta
        property="article:modified_time"
        content="2023-04-07T10:17:17+00:00"
      /> */}
      <meta property="og:image" content={urlImage} />
      <meta property="og:image:width" content="612" />
      <meta property="og:image:height" content="408" />
      <meta property="og:image:type" content="image/png" />
      <meta name="author" content="taufid" />
      {/* <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:label1" content="Written by" />
      <meta name="twitter:data1" content="taufid" />
      <meta name="twitter:label2" content="Est. reading time" />
      <meta name="twitter:data2" content="4 minutes" /> */}

      {/* <link
        rel="alternate"
        type="application/rss+xml"
        title=" » Feed"
        href="https://kagetnews.com/feed/"
      />
      <link
        rel="alternate"
        type="application/rss+xml"
        title=" » Comments Feed"
        href="https://kagetnews.com/comments/feed/"
      />
      <link
        rel="alternate"
        type="application/rss+xml"
        title=" » Polemik Thrifting &amp; Hak Konsumen Untuk Memilih Barang Comments Feed"
        href="https://kagetnews.com/opini/polemik-thrifting-hak-konsumen-untuk-memilih-barang/feed/"
      />

      <link
        rel="stylesheet"
        id="wp-block-library-css"
        href="https://kagetnews.com/wp-includes/css/dist/block-library/style.min.css?ver=6.2"
        media="all"
      />
      <link
        rel="stylesheet"
        id="quads-style-css-css"
        href="https://kagetnews.com/wp-content/plugins/quick-adsense-reloaded/includes/gutenberg/dist/blocks.style.build.css?ver=2.0.68"
        media="all"
      />
      <link
        rel="stylesheet"
        id="embedpress_blocks-cgb-style-css-css"
        href="https://kagetnews.com/wp-content/plugins/embedpress/Gutenberg/dist/blocks.style.build.css?ver=1673393724"
        media="all"
      />
      <link
        rel="stylesheet"
        id="classic-theme-styles-css"
        href="https://kagetnews.com/wp-includes/css/classic-themes.min.css?ver=6.2"
        media="all"
      />

      <link
        rel="stylesheet"
        id="embedpress-css"
        href="https://kagetnews.com/wp-content/plugins/embedpress/assets/css/embedpress.css?ver=6.2"
        media="all"
      />
      <link
        rel="stylesheet"
        id="hello-elementor-css"
        href="https://kagetnews.com/wp-content/themes/hello-elementor/style.min.css?ver=2.6.1"
        media="all"
      />
      <link
        rel="stylesheet"
        id="hello-elementor-theme-style-css"
        href="https://kagetnews.com/wp-content/themes/hello-elementor/theme.min.css?ver=2.6.1"
        media="all"
      />
      <link
        rel="stylesheet"
        id="elementor-icons-css"
        href="https://kagetnews.com/wp-content/plugins/elementor/assets/lib/eicons/css/elementor-icons.min.css?ver=5.17.0"
        media="all"
      />
      <link
        rel="stylesheet"
        id="elementor-frontend-css"
        href="https://kagetnews.com/wp-content/plugins/elementor/assets/css/frontend-lite.min.css?ver=3.10.2"
        media="all"
      />

      <link
        rel="stylesheet"
        id="embedpress-elementor-css-css"
        href="https://kagetnews.com/wp-content/plugins/embedpress/assets/css/embedpress-elementor.css?ver=3.6.4"
        media="all"
      />
      <link
        rel="stylesheet"
        id="elementor-pro-css"
        href="https://kagetnews.com/wp-content/plugins/elementor-pro/assets/css/frontend-lite.min.css?ver=3.6.2"
        media="all"
      />
      <link
        rel="stylesheet"
        id="dashicons-css"
        href="https://kagetnews.com/wp-includes/css/dashicons.min.css?ver=6.2"
        media="all"
      />
      <link
        rel="stylesheet"
        id="eael-general-css"
        href="https://kagetnews.com/wp-content/plugins/essential-addons-for-elementor-lite/assets/front-end/css/view/general.min.css?ver=5.5.3"
        media="all"
      />
      <link
        rel="stylesheet"
        id="google-fonts-1-css"
        href="https://fonts.googleapis.com/css?family=Poppins%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7COpen+Sans%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CSora%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&amp;display=auto&amp;ver=6.2"
        media="all"
      />
      <link
        rel="stylesheet"
        id="elementor-icons-shared-0-css"
        href="https://kagetnews.com/wp-content/plugins/elementor/assets/lib/font-awesome/css/fontawesome.min.css?ver=5.15.3"
        media="all"
      />
      <link
        rel="stylesheet"
        id="elementor-icons-fa-solid-css"
        href="https://kagetnews.com/wp-content/plugins/elementor/assets/lib/font-awesome/css/solid.min.css?ver=5.15.3"
        media="all"
      />
      <link
        rel="stylesheet"
        id="elementor-icons-fa-regular-css"
        href="https://kagetnews.com/wp-content/plugins/elementor/assets/lib/font-awesome/css/regular.min.css?ver=5.15.3"
        media="all"
      />

      <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin="" />
      <script
        src="https://kagetnews.com/wp-content/plugins/embedpress/assets/js/pdfobject.min.js?ver=3.6.4"
        id="embedpress-pdfobject-js"
      ></script>
      <script
        src="https://kagetnews.com/wp-includes/js/jquery/jquery.min.js?ver=3.6.3"
        id="jquery-core-js"
      ></script>
      <script
        src="https://kagetnews.com/wp-includes/js/jquery/jquery-migrate.min.js?ver=3.4.0"
        id="jquery-migrate-js"
      ></script>

      <script
        src="https://www.googletagmanager.com/gtag/js?id=UA-238137943-1"
        id="google_gtagjs-js"
        async=""
      ></script>
 */}
      <meta property="og:image" content={urlImage} />
      <meta property="og:image:secure_url" content={urlImage} />
      <meta property="og:image:width" content="612" />
      <meta property="og:image:height" content="408" />
      <meta property="og:image:alt" content={trimString(title, 35)} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:description" content={trimString(description, 155)} />
      <meta property="og:type" content="article" />
      <meta property="og:locale" content="id_ID" />
      <meta property="og:title" content={trimString(title, 35)} />
      <meta property="og:url" content={urlArticle} />
      {/* <meta property="og:updated_time" content="2023-04-07T17:17:17+07:00" /> */}
      <meta property="article:tag" content="Event" />
      <meta property="article:tag" content="Seminar" />
      <meta property="article:tag" content="Pendidikan" />
      {/* <meta
        property="article:published_time"
        content="2023-04-07T10:15:35+00:00"
      />
      <meta
        property="article:modified_time"
        content="2023-04-07T10:17:17+00:00"
      />
      <meta property="article:section" content="Opini" />
      <meta property="article:author:username" content="taufid" /> */}
      <meta property="twitter:partner" content="ogwp" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:image" content={urlImage} />
      <meta property="twitter:image:alt" content={trimString(urlImage, 35)} />
      <meta property="twitter:title" content={trimString(title, 35)} />
      <meta
        property="twitter:description"
        content={trimString(description, 155)}
      />
      <meta property="twitter:url" content={urlArticle} />
      <meta itemprop="image" content={urlImage} />
      <meta itemprop="name" content={trimString(title, 35)} />
      <meta itemprop="headline" content={trimString(title, 35)} />
      <meta itemprop="description" content={trimString(description, 155)} />
      {/* <meta itemprop="datePublished" content="2023-04-07" />
      <meta itemprop="dateModified" content="2023-04-07T10:17:17+00:00" />
      <meta itemprop="author" content="taufid" />
      <meta property="profile:username" content="taufid" /> */}

      {/* <link rel="https://api.w.org/" href="https://kagetnews.com/wp-json/" />
      <link
        rel="alternate"
        type="application/json"
        href="https://kagetnews.com/wp-json/wp/v2/posts/3453"
      />
      <link
        rel="EditURI"
        type="application/rsd+xml"
        title="RSD"
        href="https://kagetnews.com/xmlrpc.php?rsd"
      />
      <link
        rel="wlwmanifest"
        type="application/wlwmanifest+xml"
        href="https://kagetnews.com/wp-includes/wlwmanifest.xml"
      />
      <meta name="generator" content="WordPress 6.2" />
      <link rel="shortlink" href="https://kagetnews.com/?p=3453" />
      <meta name="generator" content="Site Kit by Google 1.93.0" />
      <meta
        name="google-site-verification"
        content="RuVnwx4N2uPfd7q75TPO_bcK3gE2y9LhKY6SotM3v8g"
      />
      <script type="text/javascript">
        document.cookie = "quads_browser_width=" + screen.width;
      </script> */}
      {/* <link
        rel="icon"
        href="https://kagetnews.com/wp-content/uploads/2022/07/cropped-Logo-1-1-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        href="https://kagetnews.com/wp-content/uploads/2022/07/cropped-Logo-1-1-192x192.png"
        sizes="192x192"
      />
      <link
        rel="apple-touch-icon"
        href="https://kagetnews.com/wp-content/uploads/2022/07/cropped-Logo-1-1-180x180.png"
      />
      <meta
        name="msapplication-TileImage"
        content="https://kagetnews.com/wp-content/uploads/2022/07/cropped-Logo-1-1-270x270.png"
      /> */}
    </Helmet>
  );
}
