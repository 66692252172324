import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { rootReducers } from "./reducers";
import thunk from "redux-thunk";
import { logger } from "./middleware/logger";

let store = createStore(
  rootReducers,
  applyMiddleware(
    thunk
    // logger
  )
);

export default store;
