import { COMPANY_ID } from "../constant/ids";
import { ErrorMessage } from "../helpers/ErrorMessage";
import { alertTopRight } from "../helpers/swalAlert";
import { ApiManagerOnlineCertificate } from "./ApiManager";

export const ApiGetEventList = async (setData, searchQuery) => {
  try {
    const { data: result } = await ApiManagerOnlineCertificate(
      "event/company/" + COMPANY_ID,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        params: { search: searchQuery },
      }
    );
    setData((state) => result);
  } catch (error) {
    console.log(error);
    throw ErrorMessage(error);
  }
};

export const ApiGetEventDetail = async (eventId) => {
  try {
    const { data: result } = await ApiManagerOnlineCertificate(
      "event/" + eventId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return result;
  } catch (error) {
    console.log(error);
    throw ErrorMessage(error);
  }
};

export const ApiGetCertificate = async (EventId, phone) => {
  try {
    const data = await ApiManagerOnlineCertificate("certificate/" + phone, {
      method: "GET",
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "multipart/form-data",
        EventId,
      },
    });
    alertTopRight({
      background: "#a5dc86",
      icon: "success",
      title: "Sertifikat anda akan segera di download",
    });

    return data.data;
  } catch (error) {
    console.log(error);
    await alertTopRight({
      background: "#f27474",
      icon: "error",
      title: "Data anda tidak ditemukan",
    });

    return error;
  }
};

export const ApiVerifyCertificate = async (CertificateId) => {
  try {
    const data = await ApiManagerOnlineCertificate(
      "certificate/verify/" + CertificateId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    await alertTopRight({
      background: "#a5dc86",
      icon: "success",
      title: "Sertifikat terverifikasi",
    });
    return data.data;
  } catch (error) {
    await alertTopRight({
      background: "#f27474",
      icon: "error",
      title: "Data anda tidak ditemukan",
    });
    console.log(error);
    throw error;
  }
};
