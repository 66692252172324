import { View } from "react-native";
import React, { useState } from "react";
import { IconButton, Searchbar, Text, useTheme } from "react-native-paper";
import { saveAs } from "file-saver";
import { ApiGetCertificate } from "../APIs/EventOnlineCertificate";
import Swal from "sweetalert2";
import { alertTopRight } from "../helpers/swalAlert";

export default function SearchCertificate({ EventId }) {
  const [phone, setPhone] = useState("");
  const theme = useTheme().colors;

  const downloadCertificate = async () => {
    if (!phone) {
      alertTopRight({
        background: "#f27474",
        icon: "error",
        title: "Mohon masukkan nomor HP atau email terlebih dahulu",
      });
    } else {
      const certificate = await ApiGetCertificate(EventId, phone);
      const buff = Buffer.from(certificate);
      const blob = new Blob([buff], { type: "png" });
      saveAs(blob, `/certificate-${EventId}-${phone}.png`);
    }
  };
  return (
    <View
      style={{
        width: "100%",
        alignItems: "center",
        backgroundColor: theme.primaryContainer,
        padding: 15,
        borderRadius: 20,
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 11,
        },
        shadowOpacity: 0.57,
        shadowRadius: 15.19,

        elevation: 23,
      }}
    >
      <Text
        variant="bodyLarge"
        style={{
          alignSelf: "flex-start",
          fontWeight: "700",
          textTransform: "uppercase",
        }}
      >
        Download Sertifikat
      </Text>
      <Text
        variant="bodySmall"
        style={{
          alignSelf: "flex-start",
        }}
      >
        {`(silahkan masukkan nomor HP atau Email anda)`}
      </Text>
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: 15,
        }}
      >
        <View
          style={{
            // borderWidth: 2,
            alignItems: "center",
            borderRadius: 40,
            paddingHorizontal: 20,
          }}
        >
          <IconButton
            icon={"download-box"}
            mode="outlined"
            onPress={downloadCertificate}
            style={{
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,

              elevation: 5,
            }}
          />
          <Text onPress={downloadCertificate} style={{ fontWeight: "600" }}>
            Download
          </Text>
        </View>
        <Searchbar
          placeholder="Nomor HP / Email"
          icon={"certificate"}
          value={phone}
          onChangeText={(text) => setPhone(text)}
          onSubmitEditing={downloadCertificate}
          placeholderTextColor={"grey"}
          style={{
            flex: 1,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,

            elevation: 5,
          }}
        />
      </View>
    </View>
  );
}
