import { View, Text } from "react-native";
import React from "react";
import VerificationData from "../../../components/VerificationData";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useTheme } from "react-native-paper";

export default function SignatureInfo({ data }) {
  const theme = useTheme().colors;
  return (
    <View
      style={{
        borderWidth: 2,
        borderRadius: 20,
        padding: 10,
        marginVertical: 10,
      }}
    >
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <MaterialCommunityIcons
          name={"file-sign"}
          size={20}
          color={theme.onBackground}
          style={{ marginEnd: 5 }}
        />
        <Text style={{ fontSize: 18, fontWeight: "bold" }}>
          Info Penandatanganan
        </Text>
      </View>
      <VerificationData
        iconName={"draw"}
        name={"Penandatangan"}
        description={data.Signature.name}
      />
      <VerificationData
        iconName={"domain"}
        name={"Lembaga"}
        description={data.Signature.Company.name}
      />

      <VerificationData
        iconName={"calendar-clock"}
        name={"Waktu Penandatanganan"}
        description={`${new Date(data.Event.signedAt).toLocaleString("id-ID", {
          timeStyle: "short",
          dateStyle: "full",
        })}`}
      />
    </View>
  );
}
