import { StyleSheet, View, Image } from "react-native";
import { Button, Card, IconButton, Text } from "react-native-paper";
import { addHours } from "../helpers/datePrototype";
import { EVENT_MANAGEMENT } from "../constant/baseUrl";
import { useFocusEffect, useLinkTo } from "@react-navigation/native";
import { useCallback, useState } from "react";
import { dimensionWidth } from "../helpers/responsiveSize";
import axios from "axios";

export default function CardList({ EventDetail, isEventDetail = false }) {
  const { id, name, location, time, description, duration } = EventDetail;
  const linkTo = useLinkTo();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const [imageSize, setImageSize] = useState({});
  const [cardSize, setCardSize] = useState({});
  const getImageSize = async () => {
    try {
      await Image.getSize(
        EVENT_MANAGEMENT + "/event/image/" + id,
        (width, height) => {
          console.log({ width, height });
          setImageSize({
            width,
            height,
          });
        }
      );
    } catch (error) {
      console.log("error getting");
      setImageSize((state) => {
        return { width: 0, height: 0 };
      });
    }
  };
  useFocusEffect(
    useCallback(() => {
      getImageSize();
    }, [id])
  );
  const startTime = new Date(time);
  return (
    <Card
      style={{ width: "100%", marginVertical: 5 }}
      onLayout={(event) => {
        const { width, height } = event.nativeEvent.layout;
        setCardSize({
          width,
          height,
        });
      }}
    >
      <Card.Title
        title={name}
        titleNumberOfLines={3}
        titleStyle={{
          textAlign: "center",
          textTransform: "uppercase",
          marginVertical: 5,
          fontWeight: "800",
        }}
      />
      <Card.Cover
        source={{
          uri: EVENT_MANAGEMENT + "/event/image/" + id,
        }}
        alt={name}
        resizeMode="contain"
        style={{
          height: imageSize.height
            ? imageSize.height * (cardSize.width / imageSize.width)
            : 0,
        }}
      />
      {isEventDetail && (
        <Card.Content>
          <Text variant="bodyMedium">{description}</Text>
        </Card.Content>
      )}

      <Card.Content>
        <View style={styles.cardContent}>
          <IconButton icon="map-marker-radius" size={20} />
          <Text variant="bodyMedium">{location}</Text>
        </View>
        <View style={styles.cardContent}>
          <IconButton icon="calendar-range" size={20} />
          <Text variant="bodyMedium">
            {startTime.toLocaleDateString("id-ID", options)}
          </Text>
        </View>
        <View style={styles.cardContent}>
          <IconButton icon="timetable" size={20} />
          <Text variant="bodyMedium">
            {`${startTime.toLocaleTimeString("id-ID", {
              hour: "2-digit",
              minute: "2-digit",
            })} - ${addHours(startTime, duration).toLocaleTimeString("id-ID", {
              hour: "2-digit",
              minute: "2-digit",
            })} `}
          </Text>
        </View>
      </Card.Content>

      <Card.Actions>
        {!isEventDetail && (
          <Button
            onPress={() => {
              linkTo("/event/" + id);
            }}
          >
            Detail
          </Button>
        )}
      </Card.Actions>
    </Card>
  );
}

const styles = StyleSheet.create({
  cardContent: {
    flexDirection: "row",
    alignItems: "center",
  },
});
