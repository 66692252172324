import { SEARCH_QUERY } from "../actions/actionType";

// const initialSearch = {
//   search: "",
// };

const initialSearch = "";

export default function searchReducer(state = initialSearch, action) {
  switch (action.type) {
    case SEARCH_QUERY:
      return action.data;
    default:
      return state;
  }
}
