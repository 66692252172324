import { View, Text } from "react-native";
import React from "react";
import VerificationData from "../../../components/VerificationData";

export default function CertificationReceiver({ data }) {
  return (
    <View style={{ borderWidth: 2, borderRadius: 20, padding: 10 }}>
      <VerificationData
        iconName={"account-circle-outline"}
        name={"Nama"}
        description={data.name}
      />
      <VerificationData
        iconName={"electron-framework"}
        name={"Status"}
        description={data.status}
      />
      <VerificationData
        iconName={"google-classroom"}
        name={"Nama Kegiatan"}
        description={data.Event.name}
      />
      <VerificationData
        iconName={"map-marker-radius"}
        name={"Lokasi Kegiatan"}
        description={data.Event.location}
      />
      <VerificationData
        iconName={"calendar-clock"}
        name={"Waktu Kegiatan"}
        description={`${new Date(data.Event.time).toLocaleString("id-ID", {
          timeStyle: "short",
          dateStyle: "full",
        })}`}
      />
    </View>
  );
}
